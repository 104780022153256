// import {jwt_decode} from "jwt-decode";
import { jwtDecode } from "jwt-decode";

const TOKEN_KEY = 'access_token'
const REFRESH_TOKEN_KEY = 'refresh_token'
const EXP_TOKEN_KEY = 'expiration'

const TokenService = {
  getToken () {
    return localStorage.getItem(TOKEN_KEY)
  },
  
  getAuthorization () {
    const token = JSON.parse(localStorage.getItem("TOKEN_KEY"))
    return localStorage.getItem("TOKEN_KEY")? 'Bearer ' + token.accessToken  : ''
  },

  saveToken (accessToken) {
    const TOKEN_LIFE = 24 * 60 * 60 * 1000
    localStorage.setItem(TOKEN_KEY, accessToken)
    localStorage.setItem(EXP_TOKEN_KEY, new Date().getTime() + TOKEN_LIFE)
  },

  removeToken () {
    localStorage.removeItem(TOKEN_KEY)
  },

  removeToken2 () {
    localStorage.removeItem("TOKEN_KEY")
  },

  getRefreshToken () {
    return localStorage.getItem(REFRESH_TOKEN_KEY)
  },

  saveRefreshToken (refreshToken) {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
  },

  removeRefreshToken () {
    localStorage.removeItem(REFRESH_TOKEN_KEY)
  },

  getTokenInfo(){
   
    const token = JSON.parse(localStorage.getItem("TOKEN_KEY"))
    
    if (typeof token !== 'object') return {}
    console.log(token)
    const decoded = jwtDecode(token.accessToken)
    console.log(decoded)
    console.log(new Date(decoded.exp))
    return decoded
  },

  getTokenUserInfo(){
   
    const token = JSON.parse(localStorage.getItem("TOKEN_KEY"))
    
    if (typeof token !== 'object') return {}
    return token
  },

  getRoles(){
   
    const token = JSON.parse(localStorage.getItem("TOKEN_KEY"))
    if (typeof token !== 'object') return {}
    const roles = []
    roles.push(token.roles)
    return roles
  },

  getRole(){
   
    const token = JSON.parse(localStorage.getItem("TOKEN_KEY"))
    if (typeof token !== 'object') return {}
    const role =  token.roles[0].slice(5, token.roles[0].length)
    return role
  },

  isUserLogined () {
// if(localStorage.getItem("TOKEN_KEY") ==null) return false
//     const token = JSON.parse(localStorage.getItem("TOKEN_KEY"))
//     if (typeof token !== 'object') return false
//     const decoded = jwt_decode(token.accessToken)
//     const expDate =  new Date(0)
//     expDate.setUTCSeconds(decoded.exp)
//     console.log(decoded)
//     console.log(expDate.getTime() > new Date().getTime())
//     return expDate.getTime() > new Date().getTime()

  }

}

export { TokenService }
