// import axios from 'axios'
import { post, put, get } from "@/api/api";
import { API_URL } from "@/config/api-config";

export const actions = {
  
  fetchAnnonces({ commit }, params) {
    console.clear;
    // const { pageNumber = 0, pageSize = state.pagination.size } = params
    return get(`annonces/langue/` + params).then(({ data }) => {
      commit("addannonces", data);
    });
  },

  fetchAnnoncesPage({ commit }, params) {
    
    console.clear;
    // const { pageNumber = 0, pageSize = state.pagination.size } = params
    return get(`annonces/pageable?` + params).then(({ data }) => {
      commit("addannoncesPage", data);
    });
  },

  fetchAnnoncesFacture({ commit }, params) {
    console.clear;
    // const { pageNumber = 0, pageSize = state.pagination.size } = params
    return get(`annonces/facture/${params.idFacture}?` + params.filte).then(
      ({ data }) => {
        commit("addannoncesPage", data);
      }
    );
  },
  createAnnonce({ commit }, annonce) {
    console.clear;
    return post("annonces", annonce).then(({ data }) => {
      commit("addannonce", data);
      // console.log("annonce created", annonce);
    });
  },

  creerAnnonce({ commit }, annonce) {
    console.clear;
    return post("annonces", annonce).then(({ data }) => {
      // commit("addannonce", data);
      // console.log("annonce created", annonce);
      return data;
    });
  },
  updateAnnonce({ commit }, annonce) {
    console.clear;
    return put(`annonces/${annonce.id}`, annonce).then(({ data }) => {
      commit("replaceannonce", data);
      // console.log("annonce updated", annonce);
    });
  },
  createOrUpdateAnnonce({ dispatch }, annonce) {

    console.clear;
    if (annonce.id !== undefined) {
      return dispatch("updateAnnonce", annonce);
    } else {
      return dispatch("createAnnonce", annonce);
    }
  },
  selectAnnonce({ commit }, annonce) {
    commit("setannonce", annonce);
  },
  deleteAnnonce({ commit }, annonce) {
    console.clear;
    return remove(`/annonces/${annonce.id}`, annonce).then(() => {
      commit("removeannonce", annonce);
    });
  },
  
};
