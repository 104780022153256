 import Vue from "vue";
import axios from "axios";

import { API_URL } from "@/config/api-config";
import { TokenService } from "./localStorage";



// axios.interceptors.response.use((response) => {
//     return response
// }, function (error) {
//     // Do something with response error
//     if (error.response.status === 401) {
//         console.log('unauthorized, logging out ...')
//     } else if (error.response.status === 403) {
//         console.log('Permissions denied ...')
//         // router.replace('/403')
//         Vue.$dialog.confirm({
//             text: 'Do you really want to exit?',
//             title: 'Warning'
//           })
//     }
//     return Promise.reject(error.response)
// })

export const initAxios = () => {
  axios.defaults.baseURL = API_URL;
  Vue.prototype.$axios = axios;
};

export function uploadFile(
  url,
  file,
  method = "POST",
  fileKey = "file",
  onStatusChange = () => {},
  onProgress = () => {}
) {
  if (!url || url === "") {
    console.log("Please provide the target url");
  } else if (!method || method === "") {
    console.log("Please provide file upload url ( POST / PUT )");
  }
  let formData = new FormData();

  //Send request only when user choosed a file
  if (file) formData.append(fileKey, file);

  console.log("Default upload mode");
  var xhr = new XMLHttpRequest();
  xhr.open(method, url);

  xhr.onreadystatechange = (e) => {
    if (xhr.readyState == 4) {
      if (xhr.status !== 200) {
        onStatusChange({
          error: {
            message: xhr.statusText,
            event: e,
          },
        });
      } else {
        onStatusChange("success", e);
      }
    }
  };
  (xhr.upload.onprogress = (oEvent) => {
    if (oEvent.lengthComputable) {
      let percentComplete = Math.round((oEvent.loaded * 100) / oEvent.total);
      console.log(percentComplete);
      onStatusChange({ progress: percentComplete });
      onProgress(percentComplete);
    } else {
      // Unable to compute progress information since the total size is unknown
      onProgress(false);
      onStatusChange({ progress: false });
    }
  }),
    xhr.send(formData);
}

export function downloadFile(
  url,
  filename = "filen.pdf",
  onProgress = () => {}
) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        responseType: "blob",
        headers: {
          Authorization: TokenService.getAuthorization(),
        },
        onDownloadProgress: (progressEvent) => {
          const total = parseFloat(progressEvent.total);
          const current = progressEvent.loaded;
          let percentCompleted = Math.floor((current / total) * 100);
          console.log("completed: ", percentCompleted);
          onProgress(percentCompleted);
        },
      })
      .then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", filename);
        document.body.appendChild(fileLink);

        fileLink.click();
        resolve(fileURL);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function downloadFacture(payload) {
  // console.log(payload.data);
  return axios
    .get("/report/facture/pdf/open/" + payload.id, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: TokenService.getAuthorization(),
      },
      // responseType: "blob", // responseType is a sibling of headers, not a child
    })
    .then((response) => {
      if (response.status == 200) {
        var pdfResult = response.data;
        let pdfWindow = window.open("");
        pdfWindow.document.write(
          "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
            encodeURI(pdfResult) +
            "'></iframe>"
        );
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export function downloadRecu(payload) {
  // console.log(payload.data);
  return axios
    .get("/report/recu/pdf/open/" + payload.id, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: TokenService.getAuthorization(),
      },
      // responseType: "blob", // responseType is a sibling of headers, not a child
    })
    .then((response) => {
      if (response.status == 200) {
        var pdfResult = response.data;
        let pdfWindow = window.open("");
        pdfWindow.document.write(
          "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
            encodeURI(pdfResult) +
            "'></iframe>"
        );
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export function fetchLogin(credentials) {
  return axios.post("/auth/signin", credentials);
}

export function fetchSignUp(credentials) {
  // alert()
  return axios.post("/auth/signup", credentials);
}

export function post(url, creds) {
  return axios.post(url, creds, {
    headers: {
      Authorization: TokenService.getAuthorization(),
    },
  });
}

export function put(url, creds) {
  return axios.put(url, creds, {
    headers: {
      Authorization: TokenService.getAuthorization(),
    },
  });
}
export function patch(url, creds) {
  return axios.patch(url, creds, {
    headers: {
      Authorization: TokenService.getAuthorization(),
    },
  });
}

export function get(url) {
  // console.log(axios.defaults.baseURL)
  return axios.get(url, {
    headers: {
     
      Authorization: TokenService.getAuthorization(),
    },
  });
}

export function remove(url) {
  return axios.delete(url, {
    headers: {
      Authorization: TokenService.getAuthorization(),
    },
  });
}

export const api = {
  delete: remove,
  remove,
  get,
  post,
  patch,
  put,
};
