export const state = {
  // product: undefined,
  // products: []
  annonce: {},
  annonces: [],
  pagination: {
    size: 10,
    pageable:{
      pageSize:10
    }
  },
};
