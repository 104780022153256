import Cookies from 'js-cookie';

// Fonction pour définir un cookie
export function setCookie(name, value, options = {}) {
  Cookies.set(name, value, { expires: 7, path: '', ...options });
}

// Fonction pour récupérer un cookie
export function getCookie(name) {
  return Cookies.get(name);
}

// Fonction pour supprimer un cookie
export function removeCookie(name) {
  Cookies.remove(name);
}
