export const mutations = {
  addannonces(state, annonces) {
    state.annonces = [...annonces];
    // console.log(annonces)
    state.annonces = [...annonces];
  },
  addannonce(state, annonce) {
    state.annonces = [...state.annonces, annonce];
  },
  replaceannonce(state, annonce) {
    state.annonces = state.annonces.map((struc) =>
      struc.id === annonce.id ? annonce : struc
    );
  },
  setannonce(state, annonce) {
    state.annonce = annonce.data;
  },
  addannoncesPage(state, annonce) {
    state.annonces = [...annonce.content];
    console.log(annonce.content);
    state.pagination = {
      pageable: annonce.pageable,
      totalElements: annonce.totalElements,
      totalPages: annonce.totalPages,
    };
  },
};
