// export const API_URL = "http://localhost:7001/api/accweb/";
export const API_URL = "https://delatrust.lu/api/delatrust/";
// export const API_URL = "http://95.216.119.132:8081/";
// export const API_URL = "http://192.168.1.241:8787/";


export const FILE_UPLOAD_API_URL = API_URL + "cdn/images";
export const FILE_UPLOAD_API_URL_ONE = API_URL + "files/upload?resize=false";
export const FILE_GET_URL = API_URL + "files/upload/";

export const paginationParamNames = {
  page: "page",
  rowsPerPage: "size",
  totalCount: "totalElements",
  pageCount: "totalPages",
  sortBy: "sort",
};

// Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhZS5nb2dvdW5vdS42OTMwMjMwOUBhZS5hbnBlLmJqIiwicm9sZXMiOlsiUk9MRV9BRSJdLCJ1c2VybmFtZSI6ImFlLmdvZ291bm91LjY5MzAyMzA5QGFlLmFucGUuYmoiLCJpYXQiOjE2NDg4MjQzMTUsImV4cCI6MTY0OTEyNDMxNX0.nQ9aQvpuM0R_CyClUEUku_BPKtI-mPJAxZbF-4B2CFc
