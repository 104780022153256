export const getters = {
  annonces (state) {
    return state.annonces
  },
  annonce (state) {
    return state.annonce
  },  
  pagination(state){
    return state.pagination
  }
}
