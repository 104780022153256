import Vue from 'vue'
import Vuex from 'vuex'

import annonce from "./annonce";

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  modules: {
    annonce
  }
  
})
